// Animation css
@import '~animate.css/animate.min.css';

// Bootstrap Layout scss
@import '~bootstrap/scss/bootstrap';

// Owl Carousel
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Themify icon scss
@import 'themify';

// Theme Scss
@import 'theme/variables';
:root {
  --theme-default: #ff4c3b;
  // This gradient color only for gym and marijuana layout
  --theme-gradient1: #01effc;
  --theme-gradient2: #485ff2;
}
@import 'theme/style';
@import 'theme/theme-dark';
@import 'theme/menu';
@import 'theme/responsive';

// Toastr Css
@import '~ngx-toastr/toastr.css';

// Rating Scss
@import '~ngx-bar-rating/themes/br-default-theme';


.option-variant {
  li {
      padding: 5px 10px;
      margin: 3px;
      cursor: pointer;
      &.selected {
          border: 1px solid black;
          border-radius: 5px;
          padding: 4px 9px;
      }

      &.unavailable {
          border-radius: 5px;
          color: #888888;
      }
  }
}

.color-variant {
  li {
      padding: 10px;
      margin: 2px 7px;

      &.selected {
          border:2px solid black;
      }

      &.available {
          padding: 12px;
      }

      &.unavailable {
          border: 2px solid #888888;
      }
  }
}