/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css2?family=Jomhuria');

body{
    &.outdoors {
        font-family: Roboto Condensed;

        .collection-banner {
            .contain-banner {
                h2 {
                    color: white;
                }
            }
        }   

        .full-banner {
            .banner-contain {
                h3 {
                    color: white;
                }
            }
        }

        .home-slider {
            .slider-contain {
                h1 {
                    color: white;
                }
                h4 {
                    color: var(--theme-default);
                    font-size: 24px;
                }
            }
        }
    }
}


        